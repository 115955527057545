/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';

import { useState, useEffect, useMemo } from 'react';
import Confetti from 'react-confetti'
import NoSleep from 'nosleep.js';
import { FC_IRIS_BLUE, FC_BLUE, FC_GREEN, FC_LIGHT_GREEN } from './colors';
import QRCode from './images/qrcode.png';

const DEFAULT_DURATION = 60 * 60 * 1000;

const timePerBlock = 10 * 60 * 1000; // 10 minutes

const celebratoryMessages = [
  "Way to go, champ! 🎉 You conquered the hour like a true winner! 🏆",
  "Aces! ✨ You've got more discipline than a whole team in extra time! ⚽",
  "Gold star for you! 🌟 You've shown time who's the boss! ⏰",
  "That's how you play the game! 🥳 An hour without the phone is a big win! 📵",
  "You did it! 🎊 Time flies when you're being awesome! 😎",
  "Success tastes sweeter than tea on a rainy English morning! ☕🌧️",
  "Well done! 🙌 That's the spirit that wins not just games, but life! ❤️",
  "Score! 🥅 You tackled that hour with grace and gusto! 💪",
  "Believe in yourself, and look at that! Victory's yours! 🏁🎈",
  "You did it! 🎇 You're playing the real game, and winning at life! 🥇",
];

const cheeringMessages = [
  "Nice try, partner! 🤠 But keep those hands off the screen! You've got the spirit to win! 💪",
  "Whoopsie-daisy! 🌼 Let's keep the focus strong and the screen untouched! You can do it! 🏆",
  "A little slip, no big deal! 🥳 Keep your eyes on the prize and those fingers at bay! 🎉",
  "I know it's tempting, but hands off! 🚫 You're on the road to greatness! 🌟",
  "Caught ya! 😜 But no worries, we all slip up. Keep going, and leave that screen alone! 🎈",
  "Stay strong, my friend! 🤗 The screen's off-limits, but victory's within reach! 🥇",
  "Almost there! 🎊 Keep those fingers busy with victory, not clicking! 🏁",
  "You've got the fire! 🔥 Now keep it burning without touching the screen! 🎇",
  "Hey, it happens to the best of us! 🎯 Keep your focus, and you'll be golden! 🏅",
  "A momentary lapse, but I believe in you! ❤️ Keep focused and hands-free, champ! 🥊"
];

const buttonStyle = css`
  padding: 16px 32px;
  margin: 10px 0px;
  cursor: pointer;

  border-radius: 8px;
  background: ${FC_BLUE};
  border: none;
  &:hover, &:active, &:focus { background-color: ${FC_IRIS_BLUE}; }

  color: #FFF;

  /* Application/App H1 */
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 38px; /* 105.556% */
`

const startButtonStyle = css`
  ${buttonStyle};
  background-color: ${FC_GREEN};
  &:hover, &:active, &:focus {
    background-color: ${FC_LIGHT_GREEN};
    text-shadow: 1px 1px 2px black;
  }
`

const Colophon = () => {
  return (
    <div css={css`
      font-size: 12px;
      margin-top: 30px;
    `}>
      <div>
        Created by <a href="https://www.flowclub.com" target="_blank" rel="noopener noreferrer">Flow Club</a> |
        Favicon from <a href="https://twemoji.twitter.com/" target="_blank" rel="noopener noreferrer">Twemoji</a><br/>
        OG Image by by <a href="https://unsplash.com/@seakei?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">CK Yeo</a> on <a href="https://unsplash.com/photos/VCOHfuG4PU8?utm_source=unsplash&utm_medium=referral&utm_content=creditCopyText" target="_blank" rel="noopener noreferrer">Unsplash</a>
  
      </div>
    </div>
  )
}

const DesktopModal = ({ onClose }) => {
  return (
    <div css={css`
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: rgba(0, 0, 0, 0.85);
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    `}>
      <div css={css`
        width: 400px;
        background-color: #FFF;
        padding: 30px;
        border-radius: 8px;
      `}>
        <h3>Meant for mobile</h3>
        Can you go an hour without touching your phone?<br/>
        Visit <a href="https://www.phonefreehour.com">https://www.phonefreehour.com</a> on your phone,<br/>
        Scan the QR code, or just take a look here for now.<br/>
        <img src={QRCode} alt="QR Code" title="QR Code" css={css` width: 200px; margin: 20px 0px;`}/><br/>
        <button css={buttonStyle} onClick={onClose}>Close</button>
      </div>
    </div>
  )
}

const isMobileDevice = () => {
  return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
};


export const Timer = ({ duration = DEFAULT_DURATION}) => {
  const now = Date.now()
  const [startTime, setStartTime] = useState(null);
  const [finalElapsedTime, setFinalElapsedTime] = useState(0);
  const [remainingTime, setRemainingTime] = useState(duration);
  const [timerIntervalId, setTimerIntervalId] = useState(null);
  const [wakeLock, setWakeLock] = useState(null);
  const [backupWakeLock, setBackupWakeLock] = useState(null); // NoSleep.js
  const [tryAgain, setTryAgain] = useState(false);
  const [success, setSuccess] = useState(false);
  const celebrationMessage = useMemo(() => celebratoryMessages[Math.floor(Math.random() * celebratoryMessages.length)], []);
  const [wakeLockText, setWakeLockText] = useState('Wake lock not active');
  const [warningBlock, setWarningBlock] = useState(null);
  const [warningMessage, setWarningMessage] = useState(null);
  const [copyText, setCopyText] = useState(null)
  const [goal, setGoal] = useState(null);

  const warningMessageText = useMemo(() => cheeringMessages[Math.floor(Math.random() * cheeringMessages.length)], []);
  const remainingMinutes = String(Math.floor(remainingTime / 60000)).padStart(2, '0');
  const remainingSeconds = String(Math.floor((remainingTime % 60000) / 1000)).padStart(2, '0');
  const elapsedHours = startTime ? Math.floor((now - startTime) / 3600000) : 0
  const elapsedMinutes = startTime ? Math.floor((now - startTime) % 3600000 / 60000) : 0
  const elapsedTime = startTime ? now - startTime : 0
  const elapsedSeconds = startTime ? Math.floor((now - startTime) % 60000 / 1000) : 0
  const elapsedHoursDisplay = String(elapsedHours).padStart(2, '0');
  const elapsedMinutesDisplay = String(elapsedMinutes).padStart(2, '0');
  const elapsedSecondsDisplay = String(elapsedSeconds).padStart(2, '0');
  const finalElapsedHours = Math.floor(finalElapsedTime / 3600000)
  const finalElapsedMinutes = Math.floor(finalElapsedTime % 3600000 / 60000)
  const finalElapsedSeconds = Math.floor((finalElapsedTime % 60000) / 1000)
  const finalElapsedHoursDisplay = String(finalElapsedHours).padStart(2, '0');
  const finalElapsedMinutesDisplay = String(finalElapsedMinutes).padStart(2, '0');
  const finalElapsedSecondsDisplay = String(finalElapsedSeconds).padStart(2, '0');
  const isDesktop = !isMobileDevice();
  const [showDesktopModal, setShowDesktopModal] = useState(isDesktop);

  const numBlocks = Math.ceil(duration / timePerBlock);
  const getCurrentBlock = (elapsedTime) => Math.floor(elapsedTime / timePerBlock)

  const getProgressBlock = ({ elapsedTime, warningTimeBlock }) => {
    const currentBlock = getCurrentBlock(elapsedTime);

    const blocks = Array.from({ length: numBlocks }, (_, i) =>
      i === warningTimeBlock ? "🟨" :
      (i < currentBlock ? "🟩" : "⬜️")
    ).join('');
    
    return `⏳${blocks}⌛️`;
  };

  const blockString = getProgressBlock({ elapsedTime: startTime ? elapsedTime : finalElapsedTime, warningTimeBlock: warningBlock });

  const winText = goal? `${blockString}\nI just spent ${finalElapsedHours}h${finalElapsedMinutes}m${finalElapsedSeconds}s on ${goal} without getting distracted by my phone. Try it!` : `${blockString}\nI beat the Phone Free Hour Challenge! I made it ${finalElapsedHours}h${finalElapsedMinutes}m${finalElapsedSeconds}s without touching my phone. Can you beat that?`

  const shareText = goal ? `${blockString}\nI just spent ${finalElapsedMinutes}m${finalElapsedSeconds}s on ${goal} without getting distracted by my phone. Try it!` : `${blockString}\nI made it ${finalElapsedMinutes}m${finalElapsedSeconds}s without touching my phone! Can you beat that? Join me in the Phone Free Hour challenge.`

  const shareData = {
    title: 'Phone Free Hour',
    url: 'https://www.phonefreehour.com',
  }

  const share = async (text) => {
    try {
      await navigator.share({
        ...shareData,
        text,
      })
    } catch(err) {
      await copyToClipboard(text)
    }
  }

  const copyToClipboard = async (text) => {
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(`${text} https://www.phonefreehour.com`)
      setCopyText("Copied to clipboard!")
    } else {
      setCopyText("Couldn't copy to clipboard. Please share https://www.phonefreehour.com manually.")
    }
    setTimeout(() => {
      setCopyText(null);
    }, 2000);
  }

  const activateWakeLock = async () => {
    // Request wake lock
    // Release existing wake lock, if any
    if (wakeLock) {
      await wakeLock.release();
    }
    if ('wakeLock' in navigator) {
      try {
        const lock = await navigator.wakeLock.request('screen');
        setWakeLock(lock);
        setWakeLockText('Wake lock is active.');
      } catch (err) {
        setWakeLock(null)
        setWakeLockText("Error", err.message);
      }
    } else {
      setWakeLockText('Wake lock API not supported.');
      // Fallback to NoSleep.js
      const noSleep = new NoSleep();
      setBackupWakeLock(noSleep);
      noSleep.enable();
      setWakeLockText('NoSleep.js is active.');
    }
  }

  const startTimer = async () => {
    setStartTime(Date.now());
    setRemainingTime(duration);
    setTryAgain(false);
    setWarningBlock(null);
    setWarningMessage(null);
    window.scrollTo(0,0);
    await activateWakeLock();
  };

  const stopTimer = async (cancelInterval = true) => {
    if (startTime === null) return;
    setFinalElapsedTime(Date.now() - startTime);
    setRemainingTime(0)
    setWarningMessage(null);
    if (cancelInterval) {
      if (timerIntervalId) {
        clearInterval(timerIntervalId);
      }
      setStartTime(null);
    }
    // Release wake lock if acquired
    if (wakeLock) {
      await wakeLock.release()
      setWakeLock(null);
    } else if (backupWakeLock) {
      backupWakeLock.disable();
      setBackupWakeLock(null);
    }
  };

  useEffect(() => {
    if (startTime === null) return;
    const tick = () => {
      const elapsed = Date.now() - startTime;
      const remaining = duration - elapsed
      if (remaining <= 0 && !success) {
        setRemainingTime(0);
        stopTimer(false);
        setSuccess(true)
        return;
      }
      setRemainingTime(remaining);
    };

    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden' && startTime !== null) {
        stopTimer();
        if (!success) {
          setTryAgain(true);
        }
      }
    };

    const timerInterval = setInterval(tick, 1000);
    setTimerIntervalId(timerInterval);
    window.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(timerInterval);
      window.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [startTime]);

  useEffect(() => {
    const endTimerOnInteraction = (event) => {
      if (startTime === null || event.target.nodeName === "BUTTON") { 
        return; // Ignore clicks on buttons
      }
      if (warningBlock === null && remainingTime > 0) {
        setWarningMessage(warningMessageText);
        setWarningBlock(getCurrentBlock(Date.now() - startTime));
        setTimeout(() => {
          setWarningMessage(null);
        }, 3000);
        return;
      }
  
      if (startTime !== null) {
        stopTimer();
        if (!success) {
          setTryAgain(true);
        }
      }
    };
  
    if (startTime) { // Only add event listeners if the timer is running
      document.addEventListener('click', endTimerOnInteraction);
    }
  
    return () => {
      document.removeEventListener('click', endTimerOnInteraction);
    };
  }, [startTime, warningBlock])

  if (success) {
    return (
      <div>
        <div css={css`margin: 0px 30px 30px 30px;`}>
          <h1>Phone Free Hour</h1>
          <h3>{celebrationMessage}</h3>
          According to a 2022 survey, the average US adult picks up their phone once every 2 minutes and 43s. You made it a WHOLE HOUR without touching your phone. Congrats!
        </div>
        <Confetti
          recycle={true}
          numberOfPieces={300}
          colors={['#091454', '#515EA8', '#D2DDEC', '#EAF3FF', '#D5E3E0', '#A5F3E3', '#EBB4A9', '#F9F9F9', '#333232']}
          width={window.innerWidth}
          height={window.innerHeight}
        />
        <div css={css`margin: 20px 0px;`}>
        {startTime ?
          <>
          {blockString}<br/>
          <h1>{elapsedHoursDisplay}:{elapsedMinutesDisplay}:{elapsedSecondsDisplay}</h1>
          </>
        :
          <>
          {blockString}<br/>
          <h1>{finalElapsedHoursDisplay}:{finalElapsedMinutesDisplay}:{finalElapsedSecondsDisplay}</h1>
        </>
        }
        </div>
        <button css={buttonStyle} onClick={() => share(winText)}>Challenge a friend</button>
        {copyText && <div css={css`font-size: 14px; margin: 30px;`}>{copyText}</div>}
        <Colophon />
      </div>
    )
  }
  return (
    <div css={css`display: flex; flex-direction: column;`}>
      {showDesktopModal && <DesktopModal onClose={() => setShowDesktopModal(false)} />}
      <h1>Phone Free Hour</h1>
      The challenge is simple:
      <h4>Keep this page open.<br/>
      Don't touch your phone for 1 hour.</h4>
      Can you do it?<br/>
      {tryAgain ?
        <div>
          {blockString}<br/>
          <h5>You made it</h5>
          <h1>{finalElapsedMinutesDisplay}:{finalElapsedSecondsDisplay}</h1>
          <h5>without using your phone!</h5>
          <button css={buttonStyle} onClick={() => share(shareText)}>Share</button>
        </div>
        :
        <div css={css`margin: 20px 0px;`}>
        {blockString}<br/>
        <h1>{remainingMinutes}:{remainingSeconds}</h1>
        </div>
      }
      <div>
      {startTime === null &&
        <div>
          <input
            type="text"
            placeholder="Optional: what are you focusing on?"
            onChange={(e) => setGoal(e.target.value)} 
            css={css`
              margin: 10px;

              padding: 10px;
              width: 300px;
              border-radius: 8px;
              border: 1px solid #CCC;
              font-size: 16px;
            `}
          /><br/>
          <button css={startButtonStyle} onClick={() => startTimer()}>{tryAgain ? "Try again" : "Start"}</button>
        </div>
      }<br/>
      {startTime && goal &&
        <div css={css`
          margin: 10px;

          font-size: 24px;
          color: ${FC_GREEN};
        `}>
          {goal}
        </div>
      }
      {warningMessage && <div css={css`font-size: 14px; margin: 30px;`}>{warningMessage}</div>}
      {copyText && <div css={css`font-size: 14px; margin: 30px;`}>{copyText}</div>}
      {/* {wakeLockText && <div css={css`font-size: 10px; margin-top: 30px;`}>Debug info: {wakeLockText}</div>} */}
      </div>
      {tryAgain && <Colophon />}
    </div>
  );
};
