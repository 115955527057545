/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { FC_LIGHTER_BLUE } from './colors';
import FlowClubLogo from './images/flowclublogo.svg';

import { Timer } from './Timer';

const App = () => {
  return (
    <div css={css`
      min-height: 100vh;
      width: 100vw;
      
      background: ${FC_LIGHTER_BLUE};
      font-size: 16px;
      line-height: 24px;
      font-family: "Red Hat Display", sans-serif;
      display: flex;
      text-align: center;
      flex-direction: column;
      overflow: hidden;
    `}>
      <img src={FlowClubLogo} css={css`
        margin-top: 10px;
        height: 16px;
      `} alt="Flow Club Logo" title="Flow Club Logo" />
      <div css={css`
        margin-top: 10vh;
      `}>
        <Timer />
      </div>
    </div>
  );
}

export default App;
